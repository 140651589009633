import "tailwindcss/tailwind.css";
import "@resource/atlas/components/styles.sass";
import "styles/stream-chat.scss";
import "styles/global.sass";

import { ApolloProvider } from "@apollo/client";
import { UserProvider, useUser } from "@auth0/nextjs-auth0";
import { Provider as AtlasProvider } from "@resource/atlas";
import { useClient, useFlags, withFeatureFlags } from "@resource/client-ffs";
import * as Sentry from "@sentry/nextjs";
import { AuthContextProvider } from "auth/context";
import { ExtensionControllerProvider } from "client/app/extension/__components/ExtensionControllerProvider";
import { ExtensionQuerySupport } from "client/app/extension/__components/ExtensionQuerySupport";
import AnalyticsWrapper from "components/AnalyticsWrapper";
import { ModalProvider } from "components/Generic/ModalContext";
import IdentifyUserWrapper from "components/IdentifyUserWrapper";
import MultiProvider from "components/MultiProvider";
import SatismeterWrapper from "components/SatismeterWrapper";
import { ShouldPeristCacheInitializer } from "components/ShouldPeristCacheInitializer";
import FeatureFlags from "generated/FeatureFlags";
import { useApollo } from "lib/apolloClient";
import momentDurationFormatSetup from "moment-duration-format";
import momentTz from "moment-timezone";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import queryString from "query-string";
import React, { useEffect } from "react";
import { LocationHistoryProvider } from "react-hooks/useLocationHistory";
import { TourContext } from "tours/TourContext";
import urljoin from "url-join";
import inIframe, { InV2FrameProvider } from "utils/in-iframe";
import { withAppLayout } from "utils/next-layout";
import routeForPathname from "utils/routing";

declare global {
  interface Window {
    FS?: {
      shutdown(): void;
      restart(): void;
    };
  }
}

// adapted from https://stackoverflow.com/a/9039885
const iOS =
  typeof window !== "undefined" &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((!(window as any).MSStream &&
    /iPad|iPhone|iPod/.test(window.navigator.userAgent)) ||
    // iPad on iOS 13 detection
    (window.navigator.userAgent.includes("Mac") && "ontouchend" in document));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
momentDurationFormatSetup(momentTz);

interface SentryWrapperProps {
  children?: React.ReactNode;
}

function SentryWrapper({ children }: SentryWrapperProps) {
  const { user } = useUser();
  const client = useClient();
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user?.email || undefined,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  // Capture any LD Errors with Sentry
  useEffect(() => {
    const listener = (ldError: Error): void => {
      Sentry.captureException(ldError);
    };
    client.on("error", listener);
    return (): void => {
      client.off("error", listener);
    };
  }, [client]);

  return <>{children}</>;
}

function App({ Component, pageProps }: AppProps) {
  const { user } = pageProps;
  const { client: apolloClient, clearClientStore } = useApollo(pageProps);

  const router = useRouter();

  const {
    [FeatureFlags.MAINTENANCE_MODE]: maintenanceModeEnabled,
    [FeatureFlags.QA_ASSIST_FEATURES]: isEphemeralEnvironment,
  } = useFlags();

  const appInIframe = typeof window !== "undefined" && inIframe();

  useEffect(() => {
    if (appInIframe) {
      // Handle Nylas redirects. We have to use the query-string package
      // because the nextjs router won't necessarily have the query strings
      // available with useRouter on the first render
      const params = queryString.parse(window.location.search);
      const { fromScheduler } = params;
      if (fromScheduler === "true") {
        // eslint-disable-next-line no-restricted-globals
        parent.postMessage(
          {
            params: {
              ...params,
              href: window.location.href,
            },
          },
          window.location.origin
        );
        // eslint-disable-next-line no-restricted-globals
        parent.postMessage(
          {
            params: {
              ...params,
              href: window.location.href,
            },
          },
          "https://app.guide.co"
        );
      }

      try {
        localStorage.getItem("anykey");
      } catch (error) {
        if (!router.pathname.includes("cookies-required")) {
          router.push("/cookies-required");
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const description =
    "Personalize your interview experience for every candidate, no matter where they are.";

  if (maintenanceModeEnabled) {
    if (process.env.NEXT_PUBLIC_MAINTENANCE_PAGE_URL) {
      window.location.href = process.env.NEXT_PUBLIC_MAINTENANCE_PAGE_URL;
    } else {
      console.debug("No maintenance page set. Using default");
      window.location.href = "https://status.guide.co";
    }
    return null;
  }

  const route = routeForPathname(router.pathname);

  // Wait for the apollo client to persist to local storage
  // (if that strategy is employed)
  if (!apolloClient) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{route?.title ?? route?.name ?? "Guide"}</title>
        <meta
          name="viewport"
          content={`initial-scale=1, width=device-width${
            iOS ? ", maximum-scale=1" : "" // prevent focus on zoom, see https://stackoverflow.com/q/2989263
          }`}
        />
        <meta key="ogSiteName" property="og:site_name" content="Guide" />
        <meta key="ogType" property="og:type" content="website" />
        <meta key="ogUrl" property="og:url" content="https://app.guide.co" />
        <meta
          key="ogTitle"
          property="og:title"
          content="Guide - Win more hires"
        />
        <meta key="description" name="description" content={description} />
        <meta
          key="ogDescription"
          property="og:description"
          content={description}
        />
        <meta
          key="ogImage"
          property="og:image"
          content={urljoin(
            process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? "",
            "/images/meta/guide-win-hires-img.jpg"
          )}
        />
        <meta
          key="twitterCard"
          name="twitter:card"
          content={urljoin(
            process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? "",
            "/images/meta/guide-win-hires-img.jpg"
          )}
        />
      </Head>
      <MultiProvider
        providers={[
          <InV2FrameProvider />,
          <LocationHistoryProvider />,
          <UserProvider user={user} />,
          <SentryWrapper />,
          // Apollo's types are a bit too strict, so ignore
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <ApolloProvider client={apolloClient} />,
          <AuthContextProvider
            onLogout={async () => {
              await clearClientStore();
            }}
          />,
          <IdentifyUserWrapper />,
          <AnalyticsWrapper />,
          <SatismeterWrapper />,
          <AtlasProvider />,
          <ModalProvider />,
          <ExtensionControllerProvider />,
          <ExtensionQuerySupport />,
          <ShouldPeristCacheInitializer />,
          <TourContext />,
        ]}
      >
        {withAppLayout(Component, pageProps)}
      </MultiProvider>
      {isEphemeralEnvironment && process.env.QA_GIT_SHA && (
        <div className="text-body-lg fixed bottom-0 left-0 z-50 bg-white text-red-500 opacity-50 pointer-events-none">
          QA {process.env.QA_GIT_SHA}
        </div>
      )}
    </>
  );
}
export default withFeatureFlags<AppProps>({
  user: {
    key: "anonymous-user",
    anonymous: true,
  },
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_KEY || "",
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    bootstrap: "localStorage",
  },
  flags: {
    [FeatureFlags.CREATE_A_GUIDE_AND_SHARE_WITH_CANDIDATE]: false,
    [FeatureFlags.CUSTOMER_TRIAL]: false,
    [FeatureFlags.CANDIDATES_TAB]: true,
    [FeatureFlags.CC_RECRUITER]: false,
    [FeatureFlags.UPDATER_AGENCY_LIST]: true,
    [FeatureFlags.REPLACEMENT_DATA_FOR_EVENT_TEMPLATE_INSTALLATIONS]: true,
    [FeatureFlags.PITCH_PAGES]: true,
    [FeatureFlags.SYNC_ATS_JOBS]: false,
    [FeatureFlags.GUIDE_PROCESS_HACKS]: false,
    [FeatureFlags.ENFORCE_PERMISSIONS]: false,
    [FeatureFlags.MAINTENANCE_MODE]: false,
    [FeatureFlags.MERGE_SYNC]: false,
    [FeatureFlags.GUIDES_FILTER_BY_NAME]: false,
    [FeatureFlags.NEW_INTERVIEW_TEMPLATES]: false,
    [FeatureFlags.DISABLE_USERS]: false,
    [FeatureFlags.NEW_FEEDBACK_ALGORITHM]: false,
    [FeatureFlags.USE_GUIDE_TEMPLATES]: false,
    [FeatureFlags.RESEND_INVITATIONS]: false,
    [FeatureFlags.GUIDE_METRICS_ENABLED]: false,
    [FeatureFlags.ENABLE_GOODTIME_VARIABLE_REPLACE]: false,
    [FeatureFlags.ENABLE_CHAT_ADDON]: false,
    [FeatureFlags.ENABLE_SCHEDULER_ADDON]: false,
    [FeatureFlags.ENABLE_FEEDBACK_ADDON]: false,
    [FeatureFlags.ENABLE_SOURCING_ADDON]: false,
    [FeatureFlags.VIEW_INTERVIEW_TEMPLATE_INSTALLATIONS_LIST]: false,
    [FeatureFlags.NEW_GUIDES_LIST]: false,
    [FeatureFlags.NEW_JOB_SETUP_FLOW]: false,
    [FeatureFlags.GUIDE_PREVIEW_PAGE]: false,
    [FeatureFlags.EXTENSION_LINK_FLOW]: false,
    [FeatureFlags.GATED_FEEDBACK_LANDING_PAGE]: false,
    [FeatureFlags.GATED_SOURCING_LANDING_PAGE]: false,
    [FeatureFlags.GATED_CHAT_LANDING_PAGE]: false,
    [FeatureFlags.ADD_STAGES_BULK_ACTION]: false,
    [FeatureFlags.REMOVE_STAGES_BULK_ACTION]: false,
    [FeatureFlags.EDIT_INTERVIEWS_BULK_ACTION]: false,
    [FeatureFlags.HIDE_INTERVIEWS_BULK_ACTION]: false,
    [FeatureFlags.EDIT_STAGES_BULK_ACTION]: false,
    [FeatureFlags.INTERVIEWS_IN_JOB_SETUP_FLOW]: false,
    [FeatureFlags.GUIDE_GENERATION_BY_URL]: false,
    [FeatureFlags.GUIDE_GENERATION_BY_URL_FALLBACK]: false,
    [FeatureFlags.DISABLE_GUIDE_START]: false,
    [FeatureFlags.NEW_MOVE_TO_STAGE]: false,
    [FeatureFlags.METRICS_TO_TOP_BAR]: false,
    [FeatureFlags.POLL_INTERVAL_CONFIG]: {},
    [FeatureFlags.QA_ASSIST_FEATURES]: false,
    [FeatureFlags.ZEUS_PREVIEW_EMAILS]: false,
    [FeatureFlags.ZEUS_POST_WITHOUT_NOTIFYING]: false,
    [FeatureFlags.ZEUS_DELETE_POST]: false,
    [FeatureFlags.EMAIL_EVENT_TRACKING]: false,
    [FeatureFlags.FILE_UPLOAD]: false,
    [FeatureFlags.EDITOR_LINE_ACTIONS]: false,
    [FeatureFlags.REQUEST_AVAILABILITY]: false,
    [FeatureFlags.CONTENT_EDITOR_GIFS]: false,
    [FeatureFlags.CANDIDATE_FIXED_TOOLBAR]: false,
    [FeatureFlags.CODESIGNAL_INTEGRATION]: false,
    [FeatureFlags.CANDIDATE_FEEDBACK_ZEUS]: false,
    [FeatureFlags.CANDIDATE_IDENTITY_MAGIC_TOKENS]: false,
    [FeatureFlags.CANDIDATE_IDENTITY_REPLIES]: false,
    [FeatureFlags.ENABLE_OVERLAY_CALENDARS]: false,
    [FeatureFlags.INTERVIEWS_RICH_BLOCK]: false,
    [FeatureFlags.GREENHOUSE_MESSAGE_CTA]: false,
    [FeatureFlags.DASHBOARD_INBOX]: false,
    [FeatureFlags.DISABLE_DASHBOARD_GUIDES]: false,
    [FeatureFlags.GLOBAL_GH_EXTENSION]: false,
    [FeatureFlags.PROPAGATE_GLOBAL_JOB_TEMPLATE]: false,
    [FeatureFlags.PERSIST_APOLLO_CACHE]: false,
    [FeatureFlags.RESTORE_JOB_ROLE_GUIDE_TEMPLATE_CHANGES]: false,
    [FeatureFlags.HIDE_ORGANIZATION_SWITCHER]: false,
    [FeatureFlags.CANDIDATE_TIMEZONE]: false,
    [FeatureFlags.EMBED_AVAILABILITY_IN_GREENHOUSE_SCHEDULER]: false,
    [FeatureFlags.EXTENSION_UPDATE_BANNER]: false,
    [FeatureFlags.EDIT_INTERVIEW_PLAN_IN_EXTENSION]: false,
    [FeatureFlags.CONSOLIDATED_INTERVIEW_PLAN_EDITOR]: false,
    [FeatureFlags.NEW_GUIDE_ARCHITECTURE]: false,
    [FeatureFlags.MULTIPLE_MESSAGE_TEMPLATES]: false,
    [FeatureFlags.CHROME_EXTENSION_PUBLISHED_VERSION]: "",
    [FeatureFlags.ICS_FILES_IN_MESSAGES]: false,
    [FeatureFlags.BRAND_KITS_EDIT_IN_JOBS_AND_GUIDES]: false,
    [FeatureFlags.SAVE_TEMPLATE_ON_SEND_PROMPT]: false,
    [FeatureFlags.SHOW_EXTENSION_OMNIBUTTON]: false,
    [FeatureFlags.RECRUITER_ACTIVATION_TARGETED_WORKFLOWS]: false,
    [FeatureFlags.HIJACK_GREENHOUSE_BUTTONS]: false,
    [FeatureFlags.GUIDE_HIRING_DECISIONS]: false,
    [FeatureFlags.GREENHOUSE_INTRODUCTION_TOUR]: false,
    [FeatureFlags.PRODUCT_TOURS]: false,
    [FeatureFlags.NEVER_COLLAPSE_MESSENGER_HEADER]: false,
    [FeatureFlags.RENAME_NOTIFY_TO_CC]: false,
    [FeatureFlags.EDIT_INTERVIEW_IN_MODAL]: false,
    [FeatureFlags.CHANGE_CREATE_GUIDE_BUTTON_COPY]: false,
    [FeatureFlags.DISABLE_INSIGHTS]: true,
    [FeatureFlags.V_1_SYSTEM_BANNER]: "",
  },
})(App);
